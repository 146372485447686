<template>
  <div class="loginpage">
    <p class="loginP">
      欢迎{{
        isCommit ? "登录" : showregist || isCommit ? "重置" : "注册"
      }}微企乐
    </p>
    <van-form @submit="onSubmit">
      <div class="inline">
        <van-field
          v-model="phone"
          type="number"
          name="phone"
          placeholder="请输入法人手机号"
          :rules="[{ required: true, validator }]"
        />
      </div>
      <!-- 正常登录 -->
      <div class="commet" v-if="isCommit">
        <!-- 填写验证码 -->
        <div class="inline" v-if="codeLogin">
          <van-field
            v-model="code"
            name="code"
            placeholder="请输入验证码"
            :rules="[{ required: true }]"
          />
          <div class="huoqYZM" @click="getYanZhM" v-if="showGetYZM">
            获取验证码
          </div>
          <div class="huoqYZM" v-if="!showGetYZM">
            {{ oneMinate }}s后重新获取
          </div>
        </div>
        <!-- 输入密码 -->
        <div class="inline" v-else>
          <van-field
            v-model="password"
            type="password"
            name="password"
            placeholder="请输入密码"
            :rules="[{ required: true }]"
          />
          <div></div>
        </div>
        <div class="toggle">
          <div class="left" @click="codeOrPwd" v-if="codeLogin">
            账号密码登录
          </div>
          <div class="left" @click="codeOrPwd" v-if="!codeLogin">
            手机验证码登录
          </div>
          <div class="left" @click="missPwd" v-if="!codeLogin">忘记密码</div>
        </div>
      </div>
      <!-- 设置密码 -->
      <div class="resetPwd" v-else>
        <div class="inline">
          <van-field
            v-model="newPass"
            type="password"
            name="newPass"
            placeholder="请输入新密码"
            :rules="[{ required: true }]"
          />
        </div>
        <div class="inline">
          <van-field
            v-model="code"
            name="code"
            placeholder="请输入验证码"
            :rules="[{ required: true }]"
          />
          <div class="huoqYZM" @click="getYanZhM" v-if="showGetYZM">
            获取验证码
          </div>
          <div class="huoqYZM" v-if="!showGetYZM">
            {{ oneMinate }}s后重新获取
          </div>
        </div>
      </div>
      <div style="margin: 50px">
        <van-button block type="info" :loading="formIng" native-type="submit"
          >{{
            isCommit ? "登录" : showregist || isCommit ? "确认重置" : "注册"
          }}
        </van-button>
        <div class="regist" @click="registHandle" v-if="showregist && isCommit">
          立即注册
        </div>
        <div
          class="regist"
          @click="toRegistHandle"
          v-if="!(showregist && isCommit)"
        >
          已有账号立即登录
        </div>
      </div>
    </van-form>
    <van-radio-group v-model="radio">
      <van-radio name="1" class="radio">
        <div class="showXieYi">
          已阅读并同意<a
            @click="showXieYe = true"
            style="color: rgba(0, 132, 255, 1)"
            >《授权协议》《服务协议》《隐私政策》</a
          >
        </div>
      </van-radio>
    </van-radio-group>
    <van-action-sheet v-model="showXieYe" title="授权协议">
                <div class="content">
                    <div class="dialogTitle_end">尊敬的客户：</div>
                    <p class="lei">
                        为了维护贵公司的合法权益，请在签署本企业信用信息采集授权书（以下简称“本授权书”）前，仔细阅读本授权书全部内容，以知悉贵公司在数据服务中的权利、义务。如对本授权书有任何疑问，需要进一步解读或解释，可与我们的客服联系,在线时间为工作日9：00到18:00。
                    </p>
                    <h3>北京微企利乐信息技术有限公司：</h3>
                    <p class="lei">
                        本公司（或称“授权人”）承诺并确认，截至本授权书生效之日，本公司是依据中国法律注册成立并有效存续的、住所位于中国境内（包括港澳台）的中国企业法人、事业单位及其他经济组织，具有独立承担法律责任的完全民事行为能力。
                    </p>
                    <h6>
                        一、授权内容
                    </h6>
                    <p class="lei">
                        本公司同意并不可撤销地授权：被授权人在本授权书约定的采集信息来源、信息采集范围内采集本公司过去36个月的相关信息；授权被授权人在本授权书约定的采集信息来源、信息采集范围内采集信贷建议匹配所需的相关信息；授权被授权人对采集抽取的以上本公司信息资料进行保存、整理、分析、比对、演算、归纳及（或）加工等各项操作，并将加工整理的信息资料生成报告；本公司对自身提供的所有信息真实性、完整性、准确性、合法性承担全部责任。
                        采集信息来源，指本公司授权人同意授权被授权人查询、采集并获取本公司在国家税务总局各省市、直辖市、自治区电子税务系统中提供、产生并存储在国税系统数据库的本公司信息；为了提高匹配方案的精准度，授权人同意授权被授权人通过安装软件插件后通过该软件插件采集并获取授权人储存在插件所在的电脑端上开票信息。此外，为了进一步提高方案匹配的精准度，本公司同意向被授权人提供中国人民银行金融信用信息基础数据库查询及获取的本公司或企业法人的信用报告。
                        信息采集范围，包括但不限于本公司如下信息：
                        <ul>
                            <li>
                                1、企业基本信息，是指公司的名称、注册资本、董监高信息、住所地址、企业联系电话、法定代表人基本信息（包括其姓名、手机号码、身份证号码）等；
                            </li>
                            <li>
                                2、税务信息，是指公司过去36个月内纳税情况及数据；
                            </li>
                            <li>
                                3、开票信息，是指公司过去36个月内的开票数据；
                            </li>
                            <li>
                                4、交易信息，是指公司与上下游企业的业务往来，交易数据等。财务信息，是指公司的内外账，经审计或未经审计的财务报表（包括利润表、资产负债表、损益表等）等；
                            </li>
                            <li>
                                5、进销存信息，是指客户名称及清单，客户的其它详细资料，销售目标、销售统计表等；
                            </li>
                            <li>
                                6、信贷信息，是指公司过往的信贷记录情况。税务信息，是指公司在国税、地税的缴税记录等；
                            </li>
                            <li>
                                7、诉讼信息，是指法院公布的公司作为原告或被告的案件诉讼信息；
                            </li>
                            <li>
                                8、奖惩信息，是指公司在国家政府部门是否受到奖惩的情况等。
                            </li>
                        </ul>
                    </p>

                    <h6>
                        二、授权使用范围
                    </h6>
                    <p class="lei">
                        本公司理解并同意，就信贷匹配服务而言，当第三方向被授权人查询本公司的相关信息时，该第三方应当获得本公司的事先书面同意，并在约定范围使用。
                    </p>
                    <h6>
                        三、授权期限
                    </h6>
                    <p class="lei">
                        信贷方案查询匹配服务为按次提供，授权人如需再次使用此服务则需再次签订本授权协议。
                    </p>
                    <h6>
                        四、授权书的形式
                    </h6>
                    <p class="lei">
                        授权人同意本授权书如以数据电文形式订立，授权人一旦通过在线点击签署或以其他方式确认同意本授权书，本授权书即生效。如以纸质文件“面签”形式签署，则自授权人签章之日生效。
                    </p>
                    <h6>
                        五、数据使用
                    </h6>
                    <p class="lei">
                        授权人同意被授权人有权对采集抽取的以上本公司信息资料进行保存、整理、分析、比对、演算、归纳及（或）加工等各项操作，并将加工整理的信息资料生成报告。
                    </p>

                </div>
    </van-action-sheet>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      phone: "",
      code: "",
      password: "",
      newPass: "",
      showGetYZM: true, //显示获取验证码
      oneMinate: 60,
      timer: null,
      codeLogin: true, //账号验证码登录
      isCommit: true, //正常登录
      showXieYe: false, //显示弹窗
      radio: null,
      showregist: true,
      formIng: false,
    };
  },
  created() {
    if (
      localStorage.getItem("wql_real_token") &&
      localStorage.getItem("wql_token") &&
      localStorage.getItem("wql_real_token") != "undefined"
    ) {
      this.$router.push("home");
    }
  },
  methods: {
    //手机号校验
    validator(val) {
      return /^(1)\d{10}$/.test(val);
    },
    //获取验证码计时器
    getYanZhM() {
      if (this.validator(this.phone)) {
        this.showGetYZM = false;
        this.oneMinate = 60;
        this.sendSMS();
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.oneMinate -= 1;
          if (this.oneMinate < 1) {
            clearInterval(this.timer);
            this.showGetYZM = true;
            this.timer = null;
          }
        }, 1000);
      } else {
        this.$dialog.alert({
          message: "请先输入手机号",
        });
      }
    },
    //获取验证码
    sendSMS() {
      this.$http
        .post(url.sendSMS, {
          phone: this.phone,
        })
        .then((res) => {
          if (res.code != 200) {
            this.$dialog.alert({
              message: res.msg,
            });
          }
        });
    },
    // 切换到账号密码登录
    codeOrPwd() {
      this.codeLogin = !this.codeLogin;
    },
    //切换到重置密码登录
    missPwd() {
      this.isCommit = false;
    },
    // 展示注册页面
    registHandle() {
      this.showregist = false;
      this.isCommit = false;
    },
    //返回登录页面
    toRegistHandle() {
      this.showregist = true;
      this.isCommit = true;
    },
    onSubmit(values) {
      if (!this.radio) {
        this.$dialog.alert({
          message: "请先勾选同意",
        });
        return;
      }
      let param = {
        ...values,
        appId: "web",
        appVersion: "1.0.0",
      };
      let url_ = "";
      this.formIng = true;
      if (this.isCommit) {
        url_ = url.loginPhone;
        param = {
          ...param,
          channel: 52,
        };
        if (!this.codeLogin) {
          url_ = url.usenameLogin;
          param = {
            ...param,
            username: param.phone,
          };
        }
      } else {
        url_ = url.phoneResetPwd;
      }
      this.$http.post(url_, param).then((res) => {
        this.formIng = false;
        if (res.code == 200 || res.refresh_token) {
          if (res.data == 1) {
            this.code = "";
            this.isCommit = true;
            this.codeLogin = false;
            this.$toast("密码设置成功");
          } else {
            const data = res.data ? res.data : res;
            if (!data.access_token) return;
            localStorage.setItem("wql_refresh_token", data.refresh_token);
            localStorage.setItem("wql_real_token", data.access_token);
            localStorage.setItem("wql_user_id", data.user_id);
            localStorage.setItem("wql_token", data.access_token);
            localStorage.setItem(
              "wql_expires_in",
              data.expires_in + parseInt(new Date().getTime() / 1000)
            );
            // this.$router.push("../my");
            location.reload();
          }
        } else {
          // this.$toast(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.loginpage {
  width: 100%;
  box-sizing: border-box;
  padding: 30px 20px;

  .loginP {
    font-weight: 500;
    color: #333333;
    line-height: 110px;
    font-size: 28px;
    padding-left: 20px;
  }

  .inline {
    display: flex;
    border-bottom: 1px solid rgba(235, 237, 240);
    margin: 0 20px;

    .van-cell::after {
      border-bottom: 0;
    }

    .huoqYZM {
      width: 160px;
      font-size: 14px;
      line-height: 50px;
      color: rgba(0, 132, 255, 1);
    }
  }

  .toggle {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 12px;
    line-height: 25px;
    color: rgba(0, 132, 255, 1);
  }

  .regist {
    text-align: center;
    font-size: 14px;
    line-height: 50px;
    color: rgba(0, 132, 255, 1);
  }

  .radio {
    font-size: 12px;

    /deep/ .van-icon {
      font-size: 14px;
    }
  }

  .van-action-sheet {
    height: 50vh;

    .content {
      padding: 20px;
      font-size: 12px;
      font-weight: 400;
      .lei {
        text-indent: 2em;
      }
      ._p {
        text-indent: 2em;
      }
    }
  }
}
</style>